import React from "react";
import { Line, defaults } from "react-chartjs-2";
import DownChevronIcon from "../../assets/icons/down-chevron.png";
import "./index.scss";

defaults.global.defaultFontFamily = "Circular Pro";

const data = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "My First dataset",
      fill: true,
      backgroundColor: "rgba(120,76,252, 0.15)",
      borderColor: "#784CFB",
      // borderCapStyle: "butt",
      borderJoinStyle: "miter",
      pointBorderColor: "#ffffff",
      pointBackgroundColor: "#784CFB",
      pointBorderWidth: 3,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "#784CFB",
      pointHoverBorderColor: "#ffffff",
      pointHoverBorderWidth: 3,
      pointHoverRadius: 8,
      pointRadius: 6,
      pointHitRadius: 10,
      data: [11852, 9202, 10002, 9820, 8520, 9520, 7203, 8204],
    },
  ],
};

const options = {
  plugins: {
    datalabels: {
      display: false,
    },
  },
  legend: { labels: false },
  tooltips: {
    displayColors: false,
    bodyFontSize: 15,
    callbacks: {
      title: function (tooltipItem) {
        return "";
      },
      label: function (tooltipItem) {
        return tooltipItem.yLabel;
      },
    },
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          zeroLineColor: "#fff",
        },
        ticks: {
          fontSize: 14,
          maxTicksLimit: 6,
          padding: 10,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
          zeroLineColor: "#fff",
        },
        ticks: {
          fontSize: 14,
          maxTicksLimit: 4,
          padding: 10,
        },
      },
    ],
  },
};

export default class Post extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      liked: false,
    };
  }

  _toggleLiked = () => {
    this.setState({
      liked: !this.state.liked,
    });
  };

  render() {
    const { liked } = this.state;

    return (
      <div className="graph-container line-graph-container">
        <div className="graph-top-bar">
          <div className="graph-name">Monthly Stats</div>
          <div className="dropdown">
            <div className="calender-icon"></div>
          </div>
          <div className="more">
            <div className="period-dropdown">
              <div className="period-dropdown-content">
                <div className="row">
                  <div className="label">1 week</div>
                </div>
                <div className="row">
                  <div className="label">1 month</div>
                </div>
                <div className="row">
                  <div className="label">5 months</div>
                </div>
              </div>
              Last 5 months
              <div className="period-dropdown-down-arrow">
                <img src={DownChevronIcon} alt="" />
              </div>
            </div>
            <span className="more-icon"></span>
          </div>
        </div>
        <br />
        <Line data={data} options={options} width={100} height={50} />
      </div>
    );
  }
}
