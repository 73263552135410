import React from "react";
import "./index.scss";

import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

import PlusIcon from "../../assets/icons/Plus.png";
import AttachmentIcon from "../../assets/icons/Attachment.png";
import ListIcon from "../../assets/icons/List.png";
import ImageIcon from "../../assets/icons/Image.png";
import VideoIcon from "../../assets/icons/Video.png";
import EmojiIcon from "../../assets/icons/Emoji.png";

export default class PostForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmojiBar: false,
      message: "",
      mounted: false,
    };
  }

  componentWillMount() {
    document.addEventListener("mousedown", this._handleClick, false);
  }
  componentDidMount() {
    this.setState({
      mounted: true,
    });
  }
  componentWillUnmount() {
    document.addEventListener("mousedown", this._handleClick, false);
  }

  _handleClick = (e) => {
    if (!this.node) {
      return;
    }
    if (!this.node.contains(e.target) && this.state.showEmojiBar) {
      this._toggleEmojiBar();
    }
  };

  _handleKeyDown = (e) => {
    this.setState({
      message: e.target.value,
    });

    e.target.style.height = "20px";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  _toggleEmojiBar = () => {
    this.setState({
      showEmojiBar: !this.state.showEmojiBar,
    });
  };

  _emojiSelect = (emoji) => {
    const { message } = this.state;
    const newMessage = message + emoji.native.toString();

    this.setState({
      message: newMessage,
      showEmojiBar: false,
    });
  };

  render() {
    const { showEmojiBar, message, mounted } = this.state;

    const emojiPicker = (
      <div ref={(node) => (this.node = node)}>
        <Picker
          autoFocus={true}
          set="apple"
          style={{
            position: "absolute",
            top: "0",
            display: showEmojiBar ? "block" : "none",
          }}
          color="#784CFA"
          showPreview={false}
          onSelect={this._emojiSelect}
        />
      </div>
    );

    return (
      <div className="post-form-container">
        {mounted && emojiPicker}
        <div className="attachments-bar">
          <img src={PlusIcon} className="attachment-icon" alt="" />
          <img src={AttachmentIcon} className="attachment-icon" alt="" />
          <img src={ListIcon} className="attachment-icon" alt="" />
          <img src={ImageIcon} className="attachment-icon" alt="" />
          <img src={VideoIcon} className="attachment-icon" alt="" />
        </div>
        <div className="content-container">
          <div className="emoji-icon" onClick={this._toggleEmojiBar}>
            <img src={EmojiIcon} className="emoji-icon-image" alt="" />
          </div>
          <div className="text-area">
            <textarea
              name=""
              placeholder="What is on your mind?"
              onChange={this._handleKeyDown}
              value={message}
            ></textarea>
          </div>
          <div className="post-button">Post</div>
        </div>
      </div>
    );
  }
}
