import React from "react";
import "./index.scss";
import logo from "../../assets/logo.png";

export class LivePreviewBar extends React.Component {
  render() {
    return (
      <div className="live-preview-bar">
        <div className="socially">
          <img src={logo} alt="" />
        </div>
        <div className="text">
          This is the ⚡️ live preview for Socially. All future pages included
          in this purchase 🎉
        </div>
        <a href="https://socially.eatcaviar.co/">
          <div className="buy-now-button-container">
            <div className="buy-now-button" style={{ color: "white" }}>
              Buy now
            </div>
          </div>
        </a>
      </div>
    );
  }
}
