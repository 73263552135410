import React from "react";
import "./index.scss";

import Profile from "./Profile";

import profile1 from "../../assets/images/profile1.png";
import profile2 from "../../assets/images/profile2.png";
import profile3 from "../../assets/images/profile3.png";

export default class FollowSuggestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [
        { name: "Natalie Connor", mutualFriends: 239, image: profile1 },
        { name: "Natalie Connor", mutualFriends: 239, image: profile2 },
        { name: "Natalie Connor", mutualFriends: 239, image: profile3 },
      ],
    };
  }

  render() {
    const { suggestions } = this.state;

    return (
      <div className="follow-suggestions-component-wrapper">
        <div className="title">You Should Follow</div>
        <div className="follow-suggestions">
          {suggestions.map((c, i) => (
            <Profile key={i} index={i} content={c} />
          ))}
        </div>
      </div>
    );
  }
}
