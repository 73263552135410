import React from "react";
import "./index.scss";
import logo from "../../assets/logo.png";
import SearchIcon from "../../assets/icons/search.png";
import DownChevronIcon from "../../assets/icons/down-chevron.png";
import ProfilePic from "../../assets/images/profile1.png";
import { NavLink } from "react-router-dom";

import AccountIcon from "../../assets/images/account.png";
import LogoutIcon from "../../assets/images/logout.png";

export class TopNav extends React.Component {
  render() {
    const {
      mobileSearchShown,
      toggleMobileSearchShown,
      toggleExpanded,
    } = this.props;

    return (
      <div className="top-nav row">
        <div className="brand col-xl-2 col-md-3 col-xs-12">
          <NavLink to="/" className="brand__logo">
            <img src={logo} className="brand__icon" />
            <div className="brand__name">socially</div>
          </NavLink>
          <div className="hamburger">
            <span
              className="icon icon-hamburger"
              onClick={toggleExpanded}
            ></span>
          </div>
          <div className="mobile-search" onClick={toggleMobileSearchShown}>
            <span
              className={`icon ${
                mobileSearchShown ? "icon-close" : "icon-search"
              }`}
            ></span>
          </div>
        </div>
        <div className="top-nav__right-side col-md-9 col-xs-1">
          <div className="search-bar">
            <img src={SearchIcon} className="search-icon" />
            <input
              type="text"
              className="top-nav__search-bar"
              placeholder="Search socially"
            />
          </div>
          <div className="new-post-button">+</div>
          <div className="nav-profile">
            <div className="profile-pic">
              <img src={ProfilePic} alt="" className="profile-pic__img" />
              <div className="icon">
                <img src={logo} className="icon__image" />
              </div>
            </div>
            <div className="nav-profile__name">Lisa Smith</div>
            <div className="nav-profile__dropdown-arrow">
              <img src={DownChevronIcon} alt="" />
            </div>
            <div className="nav-profile-dropdown dropdown">
              <div className="row">
                <div className="icon">
                  <img src={AccountIcon} alt="" />
                </div>
                <div className="label">Your account</div>
              </div>
              <div className="row">
                <div className="icon">
                  <img src={LogoutIcon} alt="" />
                </div>
                <div className="label">Log out</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
