import React from "react";
import "./index.scss";

export default class Event extends React.Component {
  render() {
    const { content, index } = this.props;
    const { name, date, time, attending } = content;
    const { day, number, full } = date;

    return (
      <div className="event-container">
        <div className="event-date">
          <div className="day">{day}</div>
          <div className="number">{number}</div>
        </div>
        <div className="event-info">
          <div className="event-name">{name}</div>
          <div className="event-time">
            {full} - {time}
          </div>
          <div className="event-attendees">
            {attending.map((a, i) => (
              <img
                src={
                  a
                    ? a
                    : `https://picsum.photos/40/40?random=${
                        index.toString() + i
                      }`
                }
                className="event-attendee"
                alt=""
              />
            ))}
          </div>
          <div className="event-actions">
            <div className="event-action attend-action">
              <span className="tick"></span>
              <span className="event-action-label">Attend</span>
            </div>
            <div className="event-action no-action">
              <span className="cross"></span>
              <span className="event-action-label">No thanks</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
