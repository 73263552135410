import React from "react";

import "./index.scss";

import PromomtedIcon from "../../assets/icons/promoted.png";

export default class TrendingPost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      upvoted: false,
    };
  }

  toggleUpvoted = () => {
    const { upvoted } = this.state;

    this.setState({
      upvoted: !upvoted,
    });
  };

  render() {
    const { upvoted } = this.state;
    const { postData } = this.props;
    const {
      title,
      trendingWith,
      likes,
      comments,
      promoted,
      image,
      number,
      color,
    } = postData;

    return (
      <div className="trending-post-wrapper">
        <div className="trending-post-number" style={{ background: color }}>
          {number}
        </div>
        <div className="trending-post-container">
          <div className="trending-post-image">
            <img src={image} alt="" />
          </div>
          <div className="trending-post-content-wrapper">
            <div className="trending-post-content">
              <div className="trending-post-name">{title}</div>
              <div className="trending-post-tag">
                Trending with: <span>{trendingWith}</span>
              </div>
            </div>

            <div className="trending-post-ctas">
              <div
                className="trending-post-button"
                onClick={this.toggleUpvoted}
              >
                <div className={`upvote share-icon ${upvoted && "upvoted"}`}>
                  <span className="upvote-icon"></span>
                  <span className="value">{likes}</span>
                </div>
              </div>
              <div className="trending-post-button">
                <div className="comment share-icon">
                  <span className="comment-icon"></span>
                  <span className="value">{comments}</span>
                </div>
              </div>
              {promoted && (
                <div className="promoted">
                  <span className="promoted-label">Promoted</span>
                  <span className="promoted-icon">
                    <img src={PromomtedIcon} alt="" />
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
