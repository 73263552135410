import React from "react";
import { NavLink } from "react-router-dom";
import "./index.scss";
import DownChevronIcon from "../../assets/icons/down-chevron.png";
import ProfilePic from "../../assets/images/profile1.png";

export class SideBarNav extends React.Component {
  _onClickEvent = () => {
    const { toggleExpanded } = this.props;
    if (window.innerWidth < 768) {
      toggleExpanded();
    }
  };
  _disabledClickEvent = (e) => {
    e.preventDefault();
  };
  render() {
    const { expanded, toggleExpanded } = this.props;
    console.log({ expanded });
    return (
      <div className={`side-bar-nav ${!expanded && "expanded"}`}>
        <div className={`links ${!expanded && "hide-item-names"}`}>
          <NavLink
            exact={true}
            activeClassName="active"
            className="side-bar-nav__item"
            onClick={this._onClickEvent}
            to="/"
          >
            <span className="icon icon-dashboard"></span>
            <div className="item-name">Dashboard</div>
          </NavLink>
          <NavLink
            activeClassName="active"
            className="side-bar-nav__item"
            to="/community"
            onClick={this._onClickEvent}
          >
            <span className="icon icon-community"></span>
            <div className="item-name">Community</div>
          </NavLink>
          <NavLink
            activeClassName="active"
            className="side-bar-nav__item"
            to="/trending"
            onClick={this._onClickEvent}
          >
            <span className="icon icon-trending"></span>
            <div className="item-name">Trending</div>
          </NavLink>
          <NavLink
            activeClassName="active"
            className="side-bar-nav__item"
            to="/analytics"
            onClick={this._onClickEvent}
          >
            <span className="icon icon-analytics"></span>
            <div className="item-name">Analytics</div>
          </NavLink>
          <NavLink
            activeClassName="active"
            className="side-bar-nav__item tooltip-nav"
            to="/user-search"
            onClick={this._disabledClickEvent}
          >
            <span className="tooltiptext">Coming Soon</span>
            <span className="icon icon-user-search"></span>
            <div className="item-name">User Search</div>
          </NavLink>
          <NavLink
            activeClassName="active"
            className="side-bar-nav__item tooltip-nav"
            to="/calendar"
            onClick={this._disabledClickEvent}
          >
            <span className="tooltiptext">Coming Soon</span>
            <span className="icon icon-calendar"></span>
            <div className="item-name">Calendar</div>
          </NavLink>
          <NavLink
            activeClassName="active"
            className="side-bar-nav__item tooltip-nav"
            to="/notifications"
            onClick={this._disabledClickEvent}
          >
            <span className="tooltiptext">Coming Soon</span>
            <span className="icon icon-notifications"></span>
            <div className="item-name">Notifications</div>
          </NavLink>
        </div>
        <div className={`side-bar-base ${!expanded && "hide"}`}>
          <div className="side-bar-base__profile">
            <div className="profile-pic">
              <img src={ProfilePic} alt="" className="profile-pic__img" />
              <span className="online"></span>
            </div>
            <div className="side-bar-base--profile__dropdown-arrow">
              <img src={DownChevronIcon} alt="" />
            </div>
          </div>
          <div className="side-bar-actions">
            <div className="side-bar-action tooltip">
              <span className="tooltiptext night-mode-action">
                🌙 Night mode <br />
                coming Soon
              </span>

              <span className="icon icon-dark-mode-toggle"></span>
            </div>
            <div className="side-bar-action tooltip">
              <span className="tooltiptext settings-action">Settings</span>
              <span className="icon icon-settings"></span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
