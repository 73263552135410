import React from "react";
import "./index.scss";

import Story from "./Story";
import AddToStory from "./AddToStory";

import profile1 from "../../assets/images/profile1.png";
import profile2 from "../../assets/images/profile2.png";
import profile3 from "../../assets/images/profile3.png";
import profile4 from "../../assets/images/profile4.png";
import profile5 from "../../assets/images/profile5.jpg";
import profile6 from "../../assets/images/profile6.png";

import story1 from "../../assets/images/story1.png";
import story2 from "../../assets/images/story2.png";
import story3 from "../../assets/images/story3.png";

export default class Stories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stories: [
        { name: "Chris", profilePic: profile1, storyPic: story1 },
        { name: "Zooey", profilePic: profile2, storyPic: story2 },
        { name: "Daniel", profilePic: profile3, storyPic: story3 },
        { name: "James", profilePic: profile4, storyPic: story1 },
        { name: "Violet", profilePic: profile5, storyPic: story2 },
        { name: "Tom", profilePic: profile6, storyPic: story3 },
      ],
    };
  }

  render() {
    const { stories } = this.state;

    return (
      <div className="stories-component-wrapper">
        <div className="label-bar">Stories</div>
        <div className="stories-container">
          <AddToStory />
          {stories.map((c, i) => (
            <Story key={i} index={i} content={c} />
          ))}
        </div>
      </div>
    );
  }
}
