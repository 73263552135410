import React from "react";
import "./index.scss";

import Group from "./Group";

import group1 from "../../assets/images/group1.png";
import group2 from "../../assets/images/group2.png";
import group3 from "../../assets/images/group3.png";

export default class Stories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [
        { name: "Start Coding", members: 239, posts: 32, image: group1 },
        { name: "Backyard Ideas", members: 239, posts: 32, image: group2 },
        { name: "SF Moms", members: 239, posts: 32, image: group3 },
      ],
    };
  }

  render() {
    const { groups } = this.state;

    return (
      <div className="groups-component-wrapper">
        {groups.map((c, i) => (
          <Group key={i} index={i} content={c} />
        ))}
      </div>
    );
  }
}
