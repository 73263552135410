import React from "react";

import DarkModePurple from "../../assets/icons/DarkModePurple.svg";
import SettingsPurple from "../../assets/icons/SettingsPurple.svg";
import DashboardPurple from "../../assets/icons/DashboardPurple.svg";
import CommunityPurple from "../../assets/icons/CommunityPurple.svg";
import TrendingPurple from "../../assets/icons/TrendingPurple.svg";
import AnalyticsPurple from "../../assets/icons/AnalyticsPurple.svg";
import UserSearchPurple from "../../assets/icons/UserSearchPurple.svg";
import CalendarPurple from "../../assets/icons/CalendarPurple.svg";
import NotificationsPurple from "../../assets/icons/NotificationsPurple.svg";
import HamburgerPurple from "../../assets/icons/HamburgerPurple.svg";
import ClosePurple from "../../assets/icons/ClosePurple.svg";
import HeartRed from "../../assets/icons/HeartRed.svg";
import UpvotePurple from "../../assets/icons/UpvotePurple.svg";
import CommentsPurple from "../../assets/icons/CommentsPurple.svg";
import MorePurple from "../../assets/icons/MorePurple.svg";
import TickGreen from "../../assets/icons/TickGreen.svg";
import CrossRed from "../../assets/icons/CrossRed.svg";

export default function preloadAssets() {
  return (
    <div>
      <img style={{ display: "none" }} src={DarkModePurple} />
      <img style={{ display: "none" }} src={SettingsPurple} />
      <img style={{ display: "none" }} src={DashboardPurple} />
      <img style={{ display: "none" }} src={CommunityPurple} />
      <img style={{ display: "none" }} src={TrendingPurple} />
      <img style={{ display: "none" }} src={AnalyticsPurple} />
      <img style={{ display: "none" }} src={UserSearchPurple} />
      <img style={{ display: "none" }} src={CalendarPurple} />
      <img style={{ display: "none" }} src={NotificationsPurple} />
      <img style={{ display: "none" }} src={HamburgerPurple} />
      <img style={{ display: "none" }} src={ClosePurple} />
      <img style={{ display: "none" }} src={HeartRed} />
      <img style={{ display: "none" }} src={CommentsPurple} />
      <img style={{ display: "none" }} src={UpvotePurple} />
      <img style={{ display: "none" }} src={MorePurple} />
      <img style={{ display: "none" }} src={TickGreen} />
      <img style={{ display: "none" }} src={CrossRed} />
    </div>
  );
}
