import React from 'react';
import {
  NavLink
} from "react-router-dom";
import './index.scss';
import SearchIcon from '../../assets/icons/search.png';

export class MobileNavSearch extends React.Component {
  render() {
    const { mobileSearchShown } = this.props;

    return (
      <div className={`mobile-search-container ${mobileSearchShown && 'shown'}`}>
        <div className="search-bar">
          <img src={SearchIcon} className="search-icon" />
          <input type="text" className="top-nav__search-bar" placeholder="Search socially" />
        </div>
      </div>
    );
  }
}