import React from "react";
import "./index.scss";

export default class CommunityProfile extends React.Component {
  render() {
    const { requested, clickEvent } = this.props;

    return (
      <div
        onClick={clickEvent}
        className={`community-profile-button ${requested && "requested"}`}
      >
        {requested ? "Friend requested" : "Add as friend"}
      </div>
    );
  }
}
