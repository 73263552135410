import React from "react";

import "./index.scss";

import PostForm from "../../components/PostForm";
import Stories from "../../components/Stories";
import Post from "../../components/Post";
import Groups from "../../components/Groups";
import FollowSuggestions from "../../components/FollowSuggestions";
import NearbyEvents from "../../components/NearbyEvents";

import profile1 from "../../assets/images/profile1.png";
import profile2 from "../../assets/images/profile2.png";
import profile6 from "../../assets/images/profile6.png";

import post1 from "../../assets/images/post1.png";
import post2 from "../../assets/images/post2.png";
import post3 from "../../assets/images/post3.png";
import post4 from "../../assets/images/post4.png";
import post5 from "../../assets/images/post5.png";
import post6 from "../../assets/images/post6.png";

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [
        {
          name: "Leonard Thomas",
          handle: "@leo",
          profilePic: profile1 || "https://picsum.photos/200/200?random=104",
          content:
            "The Empire State Building, it’s a must when in New York, so excited to go visit with my team this year!",
          likes: 87,
          comments: 44,
          time: "2h ago",
          images: [
            post1 || "https://picsum.photos/200/200?random=101",
            post2 || "https://picsum.photos/200/200?random=102",
            post3 || "https://picsum.photos/200/200?random=103",
          ],
        },
        {
          name: "George Preston",
          handle: "@george",
          profilePic: profile2 || "https://picsum.photos/200/200?random=204",
          content:
            "The Empire State Building, it’s a must when in New York, so excited to go visit with my team this year!",
          likes: 34,
          comments: 12,
          time: "4h ago",
          images: [
            post6 || "https://picsum.photos/200/200?random=201",
            post4 || "https://picsum.photos/200/200?random=202",
            post5 || "https://picsum.photos/200/200?random=203",
          ],
        },
        {
          name: "Michaela Hudson",
          handle: "@michaela",
          profilePic: profile6 || "https://picsum.photos/200/200?random=304",
          content:
            "The Empire State Building, it’s a must when in New York, so excited to go visit with my team this year!",
          likes: 105,
          comments: 24,
          time: "7h ago",
          images: [
            post1 || "https://picsum.photos/200/200?random=301",
            post2 || "https://picsum.photos/200/200?random=302",
            post3 || "https://picsum.photos/200/200?random=303",
          ],
        },
      ],
    };
  }

  render() {
    const { posts } = this.state;

    return (
      <div className="row page-container dashboard-page-container">
        <div className="col-xl-8 col-lg-12 dashboard-main-content">
          <PostForm />
          <br />
          <Stories />
          <br />
          <section className="posts-wrapper">
            {posts.map((postData, i) => (
              <Post postData={postData} key={i} />
            ))}
          </section>
        </div>
        {/* <div className="col-lg-1"></div> */}
        <div className="col-xl-4 col-lg-12 dashboard-sidebar">
          <div className="dashboard-sidebar__inner">
            <Groups />
            <FollowSuggestions />
            <NearbyEvents />
          </div>
        </div>
      </div>
    );
  }
}
