import React from "react";
import ContentLoader from "react-content-loader";

import "./index.scss";

import TrendingPost from "../../components/TrendingPost";

import Trending1 from "../../assets/images/Trending1.png";
import Trending2 from "../../assets/images/Trending2.png";
import Trending3 from "../../assets/images/Trending3.png";
import Trending4 from "../../assets/images/Trending4.png";
import Trending5 from "../../assets/images/Trending5.png";
import Trending6 from "../../assets/images/Trending6.png";
import Trending7 from "../../assets/images/Trending7.png";
import Trending8 from "../../assets/images/Trending8.png";
import Trending9 from "../../assets/images/Trending9.png";
import Trending10 from "../../assets/images/Trending10.png";

import SearchIcon from "../../assets/icons/search.png";

export default class Trending extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [
        {
          title: "6 Tools You Need for Your Gardening Starter Kit",
          trendingWith: "#gardening",
          likes: "1.2k",
          comments: "44",
          promoted: false,
          image: Trending1,
          number: "1",
          color: "#784CFA",
        },
        {
          title: "Best bread makers to shop, according to experts",
          trendingWith: "#gardening",
          likes: "1.2k",
          comments: "44",
          promoted: true,
          image: Trending2,
          number: "2",
          color: "#9572FF",
        },
        {
          title: "This New Eritrean-Owned Coffee Shop Is on Fire",
          trendingWith: "#coffee",
          likes: "1.2k",
          comments: "44",
          promoted: false,
          image: Trending3,
          number: "3",
          color: "#A587FF",
        },
        {
          title: "12 Healthy Smoothie Ingredients for a Morning Boost",
          trendingWith: "#health",
          likes: "1.2k",
          comments: "44",
          promoted: false,
          image: Trending4,
          number: "4",
          color: "#AEB6C2",
        },
        {
          title: "Best exercise bikes of 2020, according to personal trainers",
          trendingWith: "#biking",
          likes: "1.2k",
          comments: "44",
          promoted: false,
          image: Trending5,
          number: "5",
          color: "#AEB6C2",
        },
        {
          title: "The inflatable pool is the official symbol of summer",
          trendingWith: "#summer",
          likes: "1.2k",
          comments: "44",
          promoted: false,
          image: Trending6,
          number: "6",
          color: "#AEB6C2",
        },
        {
          title: "The beginners' guide to running jargon",
          trendingWith: "#running",
          likes: "1.2k",
          comments: "44",
          promoted: false,
          image: Trending7,
          number: "7",
          color: "#AEB6C2",
        },
        {
          title: "Rappers on Twitch",
          trendingWith: "#gaming",
          likes: "1.2k",
          comments: "44",
          promoted: false,
          image: Trending8,
          number: "8",
          color: "#AEB6C2",
        },
        {
          title: "The Best Waterproof Tech You’ll Actually Use",
          trendingWith: "#tech",
          likes: "1.2k",
          comments: "44",
          promoted: false,
          image: Trending9,
          number: "9",
          color: "#AEB6C2",
        },
        {
          title: "Roman-Style Pizza Hits U.S",
          trendingWith: "#food",
          likes: "1.2k",
          comments: "44",
          promoted: false,
          image: Trending10,
          number: "10",
          color: "#AEB6C2",
        },
      ],
    };
  }

  render() {
    const { posts } = this.state;

    return (
      <div className="row page-container trending-page-container">
        <div className="col-lg-12">
          <div className="title">
            <div className="members-title">Trending on socially</div>
            <div className="members-subtitle">
              The most talked about things on socially live here
            </div>
          </div>
          <div className="trending-members">
            {posts.map((m, i) => (
              <TrendingPost postData={m} />
            ))}
            <LoadingContent />
            <LoadingContent />
            <LoadingContent />
          </div>
        </div>
      </div>
    );
  }
}

class LoadingContent extends React.Component {
  render() {
    return (
      <div className="trending-post-wrapper loading-content">
        <ContentLoader
          speed={2}
          width={1000}
          height={100}
          viewBox="0 0 1000 100"
          backgroundColor="#B7C2D0"
          foregroundColor="#d2dfef"
        >
          <rect x="123" y="31" rx="4" ry="4" width="300" height="13" />
          <rect x="123" y="55" rx="4" ry="4" width="111" height="13" />
          {/* <rect x="711" y="34" rx="4" ry="4" width="34" height="11" /> */}
          {/* <rect x="636" y="34" rx="4" ry="4" width="52" height="11" /> */}
          <rect x="10" y="12" rx="7" ry="7" width="81" height="81" />
          <rect x="850" y="49" rx="4" ry="4" width="47" height="12" />
          <rect x="920" y="49" rx="4" ry="4" width="47" height="12" />
        </ContentLoader>
      </div>
    );
  }
}
