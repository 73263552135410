import React from "react";
import "./index.scss";

import Button from "./Button";

export default class CommunityProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requested: props.profileData.requested,
    };
  }

  _toggleRequested = () => {
    this.setState({
      requested: !this.state.requested,
    });
  };

  render() {
    const { requested } = this.state;
    const { profileData } = this.props;
    const {
      name,
      handle,
      followers,
      following,
      comments,
      time,
      images,
      profilePic,
    } = profileData;

    return (
      <div className="community-profile-container">
        <div className="community-profile-images">
          {images.map((img, i) => (
            <div className="community-profile-image">
              <img src={img} alt="" />
            </div>
          ))}
        </div>
        <div className="user-content">
          <div className="profile">
            <div className="profile-pic">
              <img src={profilePic} alt="" />
            </div>
            <div className="profile-name-handle-container">
              <div className="profile-name">{name}</div>
              <div className="profile-handle">{handle}</div>
            </div>
          </div>
        </div>
        <hr />
        <div className="followers-following">
          <div className="stat">
            <div className="number">{followers}</div>
            <div className="label">Followers</div>
          </div>
          <div className="stat">
            <div className="number">{following}</div>
            <div className="label">Following</div>
          </div>
        </div>
        <Button clickEvent={this._toggleRequested} requested={requested} />
      </div>
    );
  }
}
