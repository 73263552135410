import React from "react";
import "./index.scss";

export default class Story extends React.Component {
  render() {
    const { index, content } = this.props;

    return (
      <div
        className="story-container"
        style={{
          backgroundImage: `url(${
            content.storyPic
              ? content.storyPic
              : `https://picsum.photos/100/150?random=${index}`
          })`,
        }}
      >
        <div className="story-user">
          <div className="profile-pic">
            <img
              src={
                content.profilePic
                  ? content.profilePic
                  : `https://picsum.photos/200/200?random=${index}`
              }
              alt=""
            />
          </div>
          <div className="name">{content.name}</div>
        </div>
      </div>
    );
  }
}
