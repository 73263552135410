import React from "react";
import "./index.scss";

export default class Story extends React.Component {
  render() {
    const { content, index } = this.props;
    const { name, members, posts, image } = content;

    return (
      <div className="group-container">
        <img
          src={image ? image : `https://picsum.photos/100/100?random=${index}`}
          alt=""
        />
        <div className="group-info">
          <div className="group-name">{name}</div>
          <div className="group-stat group-members">{members} members</div>
          <div className="group-stat">{posts} posts</div>
        </div>
      </div>
    );
  }
}
