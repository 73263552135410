import React from "react";
import "./index.scss";

import Event from "./Event";

import profile1 from "../../assets/images/profile1.png";
import profile2 from "../../assets/images/profile2.png";
import profile3 from "../../assets/images/profile3.png";
import profile4 from "../../assets/images/profile4.png";
import profile5 from "../../assets/images/profile5.jpg";
import profile6 from "../../assets/images/profile6.png";

export default class NearbyEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [
        {
          name: "Travel to L.A",
          date: { full: "17 July, 2020", day: "TUE", number: "17" },
          time: "2:30pm",
          attending: [profile1, profile2, profile3],
        },
        {
          name: "San Diego Meetup",
          date: { full: "15 June, 2020", day: "MON", number: "07" },
          time: "4:30pm",
          attending: [profile4, profile5, profile6],
        },
        {
          name: "Travel to L.A",
          date: { full: "17 July, 2020", day: "SUN", number: "22" },
          time: "2:30pm",
          attending: [profile1, profile2, profile3],
        },
      ],
    };
  }

  render() {
    const { suggestions } = this.state;

    return (
      <div className="nearby-events-component-wrapper">
        <div className="title">Nearby Events</div>
        <div className="nearby-events">
          {suggestions.map((c, i) => (
            <Event key={i} index={i} content={c} />
          ))}
        </div>
      </div>
    );
  }
}
