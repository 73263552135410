import React from "react";
import { Bubble, defaults } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import "./index.scss";

defaults.global.defaultFontFamily = "'Circular Pro'";

const data = {
  datasets: [
    {
      label: "",
      data: [
        {
          x: 250,
          y: 250,
          r: 0,
        },
      ],
      backgroundColor: "rgba(200, 73, 248, 0)",
      hoverBackgroundColor: "rgba(120, 76, 251, 0)",
    },
    {
      label: "Instagram",
      data: [
        {
          x: 85,
          y: 120,
          r: 50,
        },
      ],
      backgroundColor: "rgba(13, 86, 255, .8)",
      hoverBackgroundColor: "rgba(13, 86, 255, 1)",
    },
    {
      label: "Google Ads",
      data: [
        {
          x: 125,
          y: 150,
          r: 75,
        },
      ],
      backgroundColor: "rgba(120, 76, 251, .8)",
      hoverBackgroundColor: "rgba(120, 76, 251, 1)",
    },
    {
      label: "Sponsored",
      data: [
        {
          x: 110,
          y: 75,
          r: 35,
        },
      ],
      backgroundColor: "rgba(200, 73, 248,0.8)",
      hoverBackgroundColor: "rgba(200, 73, 248, 1)",
    },
    {
      label: "Other",
      data: [
        {
          x: 130,
          y: 70,
          r: 25,
        },
      ],
      backgroundColor: "rgba(206, 214, 223, .7)",
      hoverBackgroundColor: "rgba(206, 214, 223, 1)",
    },
  ],
};

const options = {
  plugins: {
    datalabels: {
      color: "#ffffff",
      formatter: function (value) {
        return value.y;
      },
      font: {
        family: "Circular Pro",
        size: 18,
      },
    },
  },
  legend: {
    display: false,
    position: "bottom",
    labels: {
      /* filter */
      filter: function (legendItem, data) {
        return legendItem.datasetIndex !== 0;
      },
    },
  },
  tooltips: {
    displayColors: false,
    bodyFontSize: 15,
    callbacks: {
      title: function (tooltipItem) {
        return "";
      },
      label: function (tooltipItem) {
        return tooltipItem.value;
      },
    },
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          zeroLineColor: "#fff",
          display: false,
        },
        ticks: {
          beginAtZero: true,
          display: false,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          display: false,
        },
      },
    ],
  },
};

export default class BubbleChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      legend: [],
    };
  }

  componentDidMount() {
    const component = this.refs.chart;
    this.setState({ legend: component.chartInstance.legend.legendItems });
  }

  render() {
    const { legend } = this.state;

    return (
      <div className="graph-container bubble-graph-container">
        <div className="graph-top-bar">
          <div className="graph-name">Traffic Sources</div>
          <div className="dropdown"></div>
          <div className="more">
            <span className="more-icon"></span>
          </div>
        </div>
        <Bubble
          ref="chart"
          data={data}
          options={options}
          width={100}
          height={55}
        />
        <div className="legend-container">
          {legend.length &&
            legend.map((item) => {
              return (
                <div className="legend-item" key={item.text}>
                  <div
                    className="legend-item--fill"
                    style={{
                      marginRight: "8px",
                      width: "20px",
                      height: "20px",
                      backgroundColor: item.fillStyle,
                    }}
                  />
                  {item.text}
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
