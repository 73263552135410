import React from "react";

import "./index.scss";

import CommunityProfile from "../../components/CommunityProfile";

import filter from "../../assets/icons/filter.png";

import profile1 from "../../assets/images/profile1.png";
import profile2 from "../../assets/images/profile2.png";
import profile6 from "../../assets/images/profile6.png";

import post1 from "../../assets/images/post1.png";
import post2 from "../../assets/images/post2.png";
import post3 from "../../assets/images/post3.png";
import post4 from "../../assets/images/post4.png";
import post5 from "../../assets/images/post5.png";
import post6 from "../../assets/images/post6.png";

import SearchIcon from "../../assets/icons/search.png";

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [
        {
          name: "Andrew Smith",
          handle: "@andrews_1",
          profilePic: profile1 || "https://picsum.photos/200/200?random=104",
          followers: "321.1k",
          following: 391,
          verified: true,
          requested: false,
          images: [
            post1 || "https://picsum.photos/200/200?random=101",
            post2 || "https://picsum.photos/200/200?random=102",
            post3 || "https://picsum.photos/200/200?random=103",
          ],
        },
        {
          name: "Andrew Smith",
          handle: "@andrews_1",
          profilePic: profile2 || "https://picsum.photos/200/200?random=104",
          followers: "321.1k",
          following: 391,
          verified: true,
          requested: true,
          images: [
            post4 || "https://picsum.photos/200/200?random=101",
            post5 || "https://picsum.photos/200/200?random=102",
            post6 || "https://picsum.photos/200/200?random=103",
          ],
        },
        {
          name: "Andrew Smith",
          handle: "@andrews_1",
          profilePic: profile6 || "https://picsum.photos/200/200?random=104",
          followers: "321.1k",
          following: 391,
          verified: true,
          requested: false,
          images: [
            post3 || "https://picsum.photos/200/200?random=101",
            post4 || "https://picsum.photos/200/200?random=102",
            post1 || "https://picsum.photos/200/200?random=103",
          ],
        },
        {
          name: "Andrew Smith",
          handle: "@andrews_1",
          profilePic: profile2 || "https://picsum.photos/200/200?random=104",
          followers: "321.1k",
          following: 391,
          verified: true,
          requested: true,
          images: [
            post2 || "https://picsum.photos/200/200?random=101",
            post5 || "https://picsum.photos/200/200?random=102",
            post6 || "https://picsum.photos/200/200?random=103",
          ],
        },
        {
          name: "Andrew Smith",
          handle: "@andrews_1",
          profilePic: profile6 || "https://picsum.photos/200/200?random=104",
          followers: "321.1k",
          following: 391,
          verified: true,
          requested: false,
          images: [
            post1 || "https://picsum.photos/200/200?random=101",
            post2 || "https://picsum.photos/200/200?random=102",
            post3 || "https://picsum.photos/200/200?random=103",
          ],
        },
        {
          name: "Andrew Smith",
          handle: "@andrews_1",
          profilePic: profile1 || "https://picsum.photos/200/200?random=104",
          followers: "321.1k",
          following: 391,
          verified: true,
          requested: false,
          images: [
            post6 || "https://picsum.photos/200/200?random=101",
            post5 || "https://picsum.photos/200/200?random=102",
            post4 || "https://picsum.photos/200/200?random=103",
          ],
        },
      ],
    };
  }

  render() {
    const { members } = this.state;

    return (
      <div className="row page-container community-page-container">
        <div className="col-lg-12">
          <div className="title">
            <div className="members-title">3,291 community members</div>
            <div className="members-subtitle">You are monitoring</div>
          </div>
          <div className="filters">
            <div className="input">
              <img src={SearchIcon} className="search-icon" />
              <input type="text" placeholder="Search socially users" />
            </div>
            <div className="right-side">
              <div className="dropdown">
                Sort:
                <select name="cars" id="cars">
                  <option value="followers" selected="selected">
                    followers
                  </option>
                  <option value="name">name</option>
                  <option value="following">following</option>
                </select>
              </div>
              <div className="filter">
                <img src={filter} className="sort-icon" />
                Filter
              </div>
            </div>
          </div>
          <div className="community-members row">
            {members.map((m, i) => (
              <div className="col-xl-4 col-lg-6" key={i}>
                <CommunityProfile profileData={m} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
