import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { TopNav } from "./components/TopNav";
import { LivePreviewBar } from "./components/LivePreviewBar";
import { MobileNavSearch } from "./components/MobileNavSearch";
import { SideBarNav } from "./components/SideBarNav";
import "./index.scss";
import PreloadAssets from "./components/PreloadAssets";
import { AnimatedSwitch } from "react-router-transition";

import ScrollToTop from "./ScrollToTop";
import Dashboard from "./pages/Dashboard";
import Community from "./pages/Community";
import Trending from "./pages/Trending";
import Analytics from "./pages/Analytics";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
      mobileSearchShown: false,
    };
  }

  toggleExpanded() {
    this.setState({
      expanded: !this.state.expanded,
      mobileSearchShown: false,
    });
  }

  toggleMobileSearchShown() {
    this.setState({
      mobileSearchShown: !this.state.mobileSearchShown,
      expanded: true,
    });
  }

  render() {
    const { expanded, mobileSearchShown } = this.state;

    return (
      <Router>
        <ScrollToTop />
        <div className="router-container">
          <PreloadAssets />
          <LivePreviewBar />
          <TopNav
            toggleExpanded={this.toggleExpanded.bind(this)}
            mobileSearchShown={mobileSearchShown}
            toggleMobileSearchShown={this.toggleMobileSearchShown.bind(this)}
            expanded={expanded}
          />
          <div className="row">
            <div
              className={`
                col-xl-${expanded ? 2 : 1}
                col-lg-${expanded ? 3 : 1}
                col-md-${expanded ? 3 : 2}
                no-padding
                sidebar-nav-wrapper`}
            >
              <SideBarNav
                expanded={expanded}
                toggleExpanded={this.toggleExpanded.bind(this)}
              />
              <MobileNavSearch mobileSearchShown={mobileSearchShown} />
            </div>
            <div
              className={`
                col-xl-${expanded ? 10 : 11}
                col-lg-${expanded ? 9 : 11}
                col-md-${expanded ? 9 : 10}
                main-container
            `}
            >
              <AnimatedSwitch
                atEnter={{ opacity: 0 }}
                atLeave={{ opacity: 0 }}
                atActive={{ opacity: 1 }}
                className="switch-wrapper"
              >
                <Route path="/community">
                  <Community />
                </Route>
                <Route path="/trending">
                  <Trending />
                </Route>
                <Route path="/analytics">
                  <Analytics />
                </Route>
                <Route path="/user-search">
                  <UserSearch />
                </Route>
                <Route path="/calendar">
                  <Calendar />
                </Route>
                <Route path="/notifications">
                  <Notifications />
                </Route>
                <Route path="/">
                  <Dashboard />
                </Route>
              </AnimatedSwitch>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

function UserSearch() {
  return <h2>UserSearch</h2>;
}
function Calendar() {
  return <h2>Calendar</h2>;
}
function Notifications() {
  return <h2>Notifications</h2>;
}
