import React from 'react';
import './index.scss';

export default class Story extends React.Component {
  render() {
    return (
      <div
        className="story-container add-to-story"
        style={{backgroundImage: `url(https://picsum.photos/100/150)`}}
      >
        <div className="story-user">
          <div className="add-button">
            +
          </div>
          <div className="add-to-story-name">
            Add to<br />Story
          </div>
        </div>
      </div>
    );
  }
}