import React from "react";
import "./index.scss";

export default class Post extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      liked: false,
    };
  }

  _toggleLiked = () => {
    this.setState({
      liked: !this.state.liked,
    });
  };

  render() {
    const { liked } = this.state;
    const { postData } = this.props;
    const {
      name,
      handle,
      content,
      likes,
      comments,
      time,
      images,
      profilePic,
    } = postData;

    return (
      <div className="post-container">
        <div className="post-images">
          {images.map((img, i) => (
            <div className="post-image">
              <img src={img} alt="" />
            </div>
          ))}
        </div>
        <div className="user-content">
          <div className="profile">
            <div className="profile-pic">
              <img src={profilePic} alt="" />
            </div>
            <div className="profile-name-handle-container">
              <div className="profile-name">{name}</div>
              <div className="profile-handle">{handle}</div>
            </div>
          </div>
          <span className="more-icon"></span>
        </div>
        <div className="post-content">{content}</div>
        <div className="post-bottom-bar">
          <div className="share">
            <div className="like share-icon" onClick={this._toggleLiked}>
              <span className={`like-icon ${liked && "liked"}`}></span>
              <span className="value">{likes}</span>
            </div>
            <div className="comment share-icon">
              <span className="comment-icon"></span>
              <span className="value">{comments}</span>
            </div>
          </div>
          <div className="posted-at">{time}</div>
        </div>
      </div>
    );
  }
}
