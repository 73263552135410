import React from "react";

import "./index.scss";

import LineGraph from "../../components/LineGraph";
import BubbleChart from "../../components/BubbleChart";

import Graph1 from "../../assets/images/Graph1.png";
import Graph2 from "../../assets/images/Graph2.png";
import Graph3 from "../../assets/images/Graph3.png";
import Graph4 from "../../assets/images/Graph4.png";
import Studied from "../../assets/images/Studied.png";
import UpArrow from "../../assets/images/UpArrow.png";
import DownArrow from "../../assets/images/DownArrow.png";

export default class Analytics extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="row page-container analytics-page-container">
        <div className="title col-xs-12">
          <div className="members-title">Your analytics on socially</div>
          <div className="members-subtitle">
            The most talked about things on socially live here
          </div>
          <br />
        </div>
        <div className="analytics-graphs row">
          <div className="col-xl-8">
            <LineGraph />
            <BubbleChart />
          </div>
          <div className="col-xl-4">
            <div className="mini-graphs-container">
              {/* Graph 1 */}
              <div className="mini-graph-container">
                <div className="title">Website click-throughs</div>
                <div className="stats">
                  <div className="number">2</div>
                  <div className="change">
                    <div className="arrow">
                      <img src={UpArrow} alt="" className="up-arrow" />
                    </div>
                    <div className="change-amount">2.0%</div>
                  </div>
                </div>
                <div className="graph">
                  <img src={Graph1} alt="" className="" />
                </div>
              </div>
              {/* Graph 2 */}
              <div className="mini-graph-container">
                <div className="title">Profile views</div>
                <div className="stats">
                  <div className="number">2.3k</div>
                  <div className="change">
                    <div className="arrow">
                      <img src={UpArrow} alt="" className="up-arrow" />
                    </div>
                    <div className="change-amount">82.0%</div>
                  </div>
                </div>
                <div className="graph">
                  <img src={Graph2} alt="" className="" />
                </div>
              </div>
              {/* Graph 3 */}
              <div className="mini-graph-container">
                <div className="title">Socially mentions</div>
                <div className="stats">
                  <div className="number">82</div>
                  <div className="change down">
                    <div className="arrow">
                      <img src={DownArrow} alt="" className="up-arrow" />
                    </div>
                    <div className="change-amount">29.1%</div>
                  </div>
                </div>
                <div className="graph">
                  <img src={Graph3} alt="" className="" />
                </div>
              </div>
              {/* Graph 4 */}
              <div className="mini-graph-container">
                <div className="title">Profile Views</div>
                <div className="stats">
                  <div className="number">26</div>
                  <div className="change">
                    <div className="arrow">
                      <img src={UpArrow} alt="" className="up-arrow" />
                    </div>
                    <div className="change-amount">82.0%</div>
                  </div>
                </div>
                <div className="graph">
                  <img src={Graph4} alt="" className="" />
                </div>
              </div>

              {/* User Professions */}
              <div className="mini-graph-container">
                <div className="title">User professions</div>
                <div className="stats">
                  <div className="number">83k</div>
                  <div className="title user-studied">Users studied</div>
                </div>
                <div className="graph">
                  <img src={Studied} alt="" className="" />
                </div>
                <div className="professions">
                  <div className="profession">
                    <div className="flex-grow">
                      <div
                        className="color"
                        style={{ background: "#6D3CFF" }}
                      ></div>
                      <div className="profession-name">Technology</div>
                    </div>
                    <div className="views-label">22k views</div>
                  </div>
                  <div className="profession">
                    <div className="flex-grow">
                      <div
                        className="color"
                        style={{ background: "#C849F8" }}
                      ></div>
                      <div className="profession-name">Real estate</div>
                    </div>
                    <div className="views-label">20k views</div>
                  </div>
                  <div className="profession">
                    <div className="flex-grow">
                      <div
                        className="color"
                        style={{ background: "#0D56FF" }}
                      ></div>
                      <div className="profession-name">Engineering</div>
                    </div>
                    <div className="views-label">19k views</div>
                  </div>
                  <div className="profession">
                    <div className="flex-grow">
                      <div
                        className="color"
                        style={{ background: "#FFD63E" }}
                      ></div>
                      <div className="profession-name">Entertainment</div>
                    </div>
                    <div className="views-label">1k views</div>
                  </div>
                  <div className="profession">
                    <div className="flex-grow">
                      <div
                        className="color"
                        style={{ background: "#CED6DF" }}
                      ></div>
                      <div className="profession-name">Other</div>
                    </div>
                    <div className="views-label">3k views</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
