import React from "react";
import "./index.scss";

export default class Suggestion extends React.Component {
  render() {
    const { content, index } = this.props;
    const { name, mutualFriends, image } = content;

    return (
      <div className="suggestion-profile-container">
        <img
          src={image ? image : `https://picsum.photos/100/100?random=${index}`}
          alt=""
        />
        <div className="suggestion-profile-info">
          <div className="suggestion-profile-name">{name}</div>
          <div className="suggestion-profile-mutual-friends">
            {mutualFriends} friends in common
          </div>
        </div>
        <div className="add-button">+</div>
      </div>
    );
  }
}
